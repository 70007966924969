.App {
    font-family: sans-serif;
    text-align: center;
  }

  
.td-cell{
  width: 100px !important;
  /* background-color: aliceblue !important; */
  text-align: center !important;
}

  